import React, { useContext, useState } from "react"
import { Router } from "@reach/router"
import { navigate, Link } from "gatsby"
import "../global.css"
import { AppDataContext, AppDataStore, BookNote } from "../AppDataStore"
import {
  Boxf as Cols,
  Boxfc as Rows,
  Box,
  BoxCont,
  H4,
  H1,
  H3,
  Txt,
  Button,
} from "../Reusable"
import { jdump } from "../utils"
const IconButton = require("@material-ui/core/IconButton").default
const ClearIcon = require("@material-ui/icons/Clear").default
const FileCopyIcon = require("@material-ui/icons/FileCopy").default

function copyFormatted(html) {
  // Create an iframe (isolated container) for the HTML
  var container: any = document.createElement("div")
  container.innerHTML = html

  // Hide element
  container.style.position = "fixed"
  container.style.pointerEvents = "none"
  //container.style.opacity = 0

  // Detect all style sheets of the page
  var activeSheets = Array.prototype.slice
    .call(document.styleSheets)
    .filter(function (sheet) {
      return !sheet.disabled
    })

  // Mount the iframe to the DOM to make `contentWindow` available
  document.body.appendChild(container)

  // Copy to clipboard
  window.getSelection().removeAllRanges()

  var range = document.createRange()
  range.selectNode(container)
  window.getSelection().addRange(range)

  document.execCommand("copy")
  for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true
  document.execCommand("copy")
  for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false

  // Remove the iframe
  document.body.removeChild(container)
}

function getNotionHtml(tableOfContent: string[]) {
  return `<ul>` + tableOfContent.map((e) => `<li>${e}</li>`).join("") + `</ul>`
}

function getNotionHtmlBookNote(bookNote: BookNote) {
  const x =
    `<ul>` +
    bookNote?.notes.map((e) => `<li>p${e.page} - ${e.content}</li>`).join("") +
    `</ul>`
  return x;
}

const FilePage = (props) => {
  const { getFileDataFromSlug, goHome } = useContext(AppDataContext)
  const [file, setFile] = useState(getFileDataFromSlug(props.slug))

  if (file == null) navigate("/")
  console.log("got", file)
  return (
    <>
      <style>{`
            .bg {
                position:absolute;
                width: 100%;
                height: 120px;
                background-color: #3f51b5;
                z-index:-1;
            }
        `}</style>
      <div className="bg"></div>
      <BoxCont>
        <Rows>
          <Cols justify="flex-end" pt={4}>
            <Link to={`/`}>
              <IconButton size="medium">
                <ClearIcon fontSize="large" />
              </IconButton>
            </Link>
          </Cols>
          <Cols>
            <Box bgcolor="#2D3748" height={150} width={100}></Box>
            <Rows ml={2} mt={4}>
              <Box>
                <H3>{file.name}</H3>
              </Box>
              <Box mt={0.5}>
                <Txt>description</Txt>
              </Box>
            </Rows>
          </Cols>
          <Rows>
            <Cols align="center">
              <H4 mr={2}>table of contents</H4>
              <div></div>
            </Cols>
            <details className="notion-toggle">
              <summary style={{ display: "flex", alignItems: "center" }}>
                table of contents
                <Box ml={2}>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    startIcon={<FileCopyIcon />}
                    onClick={() => {
                      setTimeout(() => {
                        copyFormatted(getNotionHtml(file.tableOfContent))
                      }, 300)
                    }}
                  >
                    Copy To clipboard
                  </Button>
                </Box>
              </summary>
              <div>
                <ul>
                  {file.tableOfContent.map((e) => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
              </div>
            </details>
            <Box mt={1}>
              <details className="notion-toggle">
                <summary style={{ display: "flex", alignItems: "center" }}>
                  notes{" "}
                  <Box ml={2}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      startIcon={<FileCopyIcon />}
                      disabled={file.bookNote == null}
                      onClick={() => {
                        setTimeout(() => {
                          copyFormatted(getNotionHtmlBookNote(file.bookNote))
                        }, 300)
                      }}
                    >
                      Copy To clipboard
                    </Button>
                  </Box>
                </summary>
                <div>
                  <ul>
                    {file.bookNote?.notes.map((e, i) => (
                      <li key={i}>{e.content}</li>
                    ))}
                  </ul>
                </div>
              </details>
            </Box>
          </Rows>
        </Rows>
      </BoxCont>
    </>
  )
}

const FileRouter = ({}: {}) => {
  return (
    <AppDataStore>
      <Router>
        <FilePage path="/file/:slug" />
      </Router>
    </AppDataStore>
  )
}

export default FileRouter
